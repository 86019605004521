.service-card{
    width: 250px;
    height: 300px;
    text-align: center;
    background-color: rgba(255, 255, 255,0.7);
    border-radius: 24px;
    font-family: 'Noto Sans';
    color: #0b3b60;
    margin: 20px;
    font-weight: 500;
     font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: transform 0.4s ease-in-out;
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    &:hover{
        transform: scale(1.06);
    font-family: 'Noto Sans';
    }
    &__img img{
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 12px;
    }
    &__title{
       padding: 15px;
    }
}
@media only screen and (max-width: 864px) {
.service-card {
    margin: 8px;
    height: 240px;
    width: 250px;
    &__img img {
            padding: 10px 10px 0;
        }
        &__title{
       padding-top: 2px;
       font-size: 10px;
    }
    background-color: #fff;
}
.project-card{
    height: 170px;
    width: 170px;
    .service-card__title{
        font-size: 8px;
    }
}
}