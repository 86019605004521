.carousel {
    display: flex;
    overflow: hidden;
    max-width: 100vw;
    position: relative;
    margin-top: 3rem;
    padding-bottom: 5rem;
}

.card {
    flex: 1 0 16.66%;
    flex-wrap: wrap;
    /* Adjust this percentage based on how many cards you want visible */
    transition: transform 1s ease-in-out;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f9f7f8;
}
.card-text{
    text-align: center;
    max-width: 190px;
    font-size: 12px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #1f2d85;
}
.rc-carousel-item {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 150px;
    height: 150px;
    margin: 20px;
    border-radius: 50px;
    box-shadow: 9px 9px 9px #e3e1e2,
        -9px -9px 9px #e3e1e2;
    background-color: #fff;
}

.rc-carousel-item-image {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    border-radius: 50px;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.carousel .card {
    animation: slide 3s ease-in-out;
}

.carousel .card:last-child {
    animation: slideIn 3s ease-in-out;
}
@media only screen and (max-width: 864px) {
    .rc-carousel-item{
        width: 80px;
        height: 80px;
        border-radius: 12px;
        margin: 0.5rem;
    }
}