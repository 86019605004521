.gallery{
    background-image: url('./../../images/gallery.png');
        background-size: cover;
        height: 100vh;
}
@media only screen and (max-width: 864px) {
    .gallery{
    background-image: url('./../../images/gallery-mini.png');
    }
}
