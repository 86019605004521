.projects{
    background-color: #f9f7f8;
    &__prehead{
        height: 90px;
    }
    &__heading{
        padding-bottom: 3rem;
        color: #f37922;
        font-family: 'Cinzel';
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        span{
            color:  #1f2d85 ;
        }
    }
    &__body{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 864px) {
    .projects__heading{
        font-size: 16px;
        padding: 2rem 0;
    }
}