.footer{
    background-image: linear-gradient(rgb(0, 0, 0,0.4),
            rgb(0, 0, 0, 0.7)), url("../../../images/blue-bg.svg");
    background-size: cover;
    padding: 2.5rem 1.5rem 0.2rem 1.5rem;
    font-family: 'Quicksand';
        font-size: 16px;
        font-weight: 500;
        color: #f9f7f8;
        line-height: 28px;
    border-bottom: 6px solid #fff;
    &__details{
    border-bottom: 0.6px solid gray;
    }
        &__heading {
            text-decoration:underline
            // border-bottom: 1px solid #fff;
        }
    .brand-img{
        filter: invert(1);
    }
    &__details2{
        display: flex;
        justify-content: space-between;
        align-items: center;
       &__para{
        flex: 1;
        padding: 10px;
       }
    }
    &__social-media{
        display: flex;
            //justify-content: center;
            align-items: center;
        
            img {
                margin: 1.5rem 1.5rem 0 0;
                filter: invert(1);
            }
    }
    &__copyright{
            text-align: center;
            font-size: 14px;
    }
}
@media only screen and (max-width: 864px) {
    .no-display-on-mobile{
        display: none;
    }
    .footer{
        font-size: 12px;
        line-height: 15px;
        padding: 0.5rem 0.5rem;
        &__details{
            padding-bottom: 0.5rem;
        }
        &__details2 {
            padding-top: 0;
            text-align: center;
            &__para{
                padding-left: 0;
            }
        }
        &__copyright{
            text-align: center;
        }
    }
}
