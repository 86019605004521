.client-outer{
    margin: 0 2.5rem;
    &__bg-heading{
        text-align: center;
        font-size: 4rem;
        color: lighten(#f9f7f8, 80%);

        text-shadow:
            -8px -8px 12px rgba(255, 255, 255, 0.4),
            8px 8px 12px rgba(0, 0, 0, 0.08);
        margin-top: 4rem;
    }
    &__heading{
        font-size: 10px;
        color: #1f2d85;
        font-family: "Quicksand", sans-serif;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        span{
            color: #f37922;
        }
    }
    &__carousel{

    }
}
.projects__prehead{
    margin-top: 6.5rem;
}
@media only screen and (max-width: 864px) {
    .client-outer{
        &__bg-heading{
            font-size: 2rem;
        }
        &__heading{
            display: block;
        font-size: 16px;
        }
    }
}