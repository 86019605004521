.home-landing-page{
    height: calc(100vh - 111px);
    padding: 0 0rem 0 2.5rem;
    display: flex;
    justify-content: space-between;
    padding-top: 45px;
    background-color: #f9f7f8;
    &__left{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 1.5rem;
        &__1{
            font-family: "Cinzel", serif;
            font-optical-sizing: auto;
            font-weight: 700;
            font-style: normal;
            color: #f37922;
            font-size: 40px;
        }
        &__2{
            font-family: "Cinzel", serif;
            font-optical-sizing: auto;
            font-weight: 700;
            font-style: normal;
            color: #1f2d85;
            font-size: 40px;
            margin-bottom: 2rem;
        }
        &__3{
            font-family: "Noto Sans", sans-serif;
            font-weight: 500;
            font-size: 19px;
            line-height: 30px;
            padding-right: 2rem;
            max-width: 600px;
        }
    }
    &__right{
        flex: 1;
        background-image: url('./../../images/home-img.png');
        background-size:cover;
        margin: 0.7rem 0;
        clip-path: polygon(25% 0%,
                    75% 0%,
                    100% 25%,
                    100% 75%,
                    75% 100%,
                    25% 100%,
                    0% 75%,
                    0% 25%);
            /* Clip path to create octagon */
        transform: scale(0.8);
    }
}
.home-pg-2{
    background-image: url('./../../images/home-bg.svg');
    background-size: cover;
    min-height: 100vh;
    width: 100vw;
    &__heading{
        color: #1f2d85;
        padding: 9rem 0 3rem 0;
        font-family: "Quicksand",
            sans-serif;
        text-align: center;
        font-size: 34px;
        font-weight: 500;
        span{
            color: #f37922;
        }
    }
    &__body{
        padding-top: 2.5rem;
        display: flex;
        justify-content: space-around;
        margin: auto;
        max-width: 1100px;
    }
}
.home-pg-3 {
    background-image: url('./../../images/bg-2.svg');
    background-size: cover;
    width: 100vw;
    &__hr{
        margin: 0 25vw;
        height: 1.5px;
        background-color: #1f2c844d;
        margin-bottom: 70px;
    }
    &__get-in-touch{ 
        padding: 12px 26px;
        padding-bottom: 18px;
        border: 0;
        font-size: 34px;
        font-weight: 500;
        transition: all 150ms ease-in-out;
        
        border-radius: 20px;
        width: fit-content;
        color: #1f2d85;
        background: #f9f7f8;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        &:hover{
            filter: brightness(0.98);
        }
        &:active {
            filter: brightness(0.97);
            padding-top: 15px;
            padding-bottom: 15px;
            box-shadow: rgba(99, 99, 99, 0.2) 0 2px 6px 0, inset 0px -1px 0px rgba(0, 0, 0, 0.15);
        }
    }
    &__heading{
            color: #1f2d85 ;
            font-family: "Quicksand",
                sans-serif;
            padding:40px 20px 20px;
            text-align: center;
            
        span{
        color: #f37922;
        }
    }
    &__body{
        display: flex;
        justify-content: center;
        height: calc(100vh - 151px);
        margin-bottom: 3rem;
        &__list{
            flex: 1;
            border-left: 2px solid #1f2c844d;
            display: flex;
            justify-content: center;
            align-items: center;
            &__contain {
                margin-top: -15%;
                margin-left: -20%;
            .capability-list-item{
                text-align: right;
                display: flex;
                align-items: center;
                padding: 12px;
                font-family: "Quicksand",
                        sans-serif;
                font-weight: 600;
                .text{
                    padding-left: 10px;
                }
            }
        }
        }
        &__img{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            .what-we-offer-img{
                background-image: url('../../images/what-we-offer2.png');
                background-size: cover;
                height: 66%;
                width: 56%;
                border-bottom-left-radius: 30%;
                border-top-right-radius: 30%;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                margin-top: -15%;
                margin-right: -20%;
            }
        }
    }
}
.home-projects{
    margin-bottom: 60px;
}
.center-the-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 864px) {
    .home-landing-page__right {
        display: none;
    }
    .home-landing-page__left{
        &__1, &__2 {
            font-size: 30px;
        }
        &__3{
            font-size: 12px;
        }
    }
    .home-pg-2__heading, .home-pg-3__get-in-touch{
        font-size: 16px;
        padding-bottom: 0.6rem;
    }
    .home-pg-3__heading{
        padding: 0;
    }
    .home-pg-2__body {
        flex-wrap: wrap;
        padding-top: 0; 
    }
    .home-pg-3__body__img {
            display: none;
    }
    .home-pg-3__hr {
        background-color: #f9f7f8;
    }
    .home-pg-3__body__list {
        background-color: rgba(193, 196, 221,0.88);
        padding-left: 50px;
        height: 60vh;
        margin-top: 40px;
        &__contain
        {   
            .capability-list-item{
                font-size: 9px;
            }
        
        }
    }
    .home-pg-3__body {
        height: calc(70vh - 151px);
    }
}