.contact{
    background-color: #f9f7f8;    
    &__body{
        background-image:  linear-gradient(rgba(31, 45, 133,0.8),
                    rgb(255, 255, 255,0.8)), url('../../images/contact-us.jpg');
        background-size: cover;
        height: calc(100vh - 8.5px);
        &__prehead {
            height: 99px;
        }
        &__heading {
                padding-top: 3rem;
                text-align: center;
                font-family: 'Cinzel', serif;
                font-size: 28px;
                color: #fff;
                font-weight: 600;
        
                span {
                    color: #f37922;
                }
            }
        &__desc {
            
            margin:25px;
            display: flex;
            justify-content: center;
            align-items: center;
            &__text{
                height: 250px;
                width: 280px;
                border: 1px solid #fff;
                border-radius: 12px;
                color: #fff;
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 19px;
                padding: 1.5rem;
                span{
                    font-weight: 900;
                    text-decoration: underline;
                    line-height: 34px;
                }
            }
        }
        &__social-media{
            display: flex;
                justify-content: center;
                align-items: center;
                img {
                    margin: 1.5rem;
                    filter: invert(1);
                }
        }
        &__connect{
            padding: 6px;
            border-radius: 8px;
            text-align: center;
        }    
    }
    
}
/* CSS */
.button-24 {
    background-color: transparent;
    background-image: linear-gradient(#fff, #f5f5fa);
    border: 0 solid #003dff;
    border-radius: 9999px;
    box-shadow: rgba(37, 44, 97, .15) 0 4px 11px 0, rgba(93, 100, 148, .2) 0 1px 3px 0;
    box-sizing: border-box;
    color: #484c7a;
    cursor: pointer;
    display: inline-block;
    font-family: Hind, system-ui, BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    margin: 4px;
    padding: 16px 24px;
    text-align: center;
    text-decoration: inherit;
    text-wrap: nowrap;
    transition: all .2s ease-out;
    transition-behavior: normal;
    white-space-collapse: collapse;
    line-height: 1.15;
}

@media (min-width: 576px) {
    .button-24 {
        padding-bottom: 10px;
        padding-top: 10px;
    }
}

.button-24:after,
.button-24:before,
.div-flex-items-center-justify-center:after,
.div-flex-items-center-justify-center:before,
.span-flex-items-center-h-16-w-auto-mr-8-py-2-flex-grow-0-flex-shrink-0-fill-current:after,
.span-flex-items-center-h-16-w-auto-mr-8-py-2-flex-grow-0-flex-shrink-0-fill-current:before,
.svg-block-h-full:after,
.svg-block-h-full:before {
    border: 0 solid #003dff;
    box-sizing: border-box;
}

.button-24:hover {
    box-shadow: rgba(37, 44, 97, .15) 0 8px 22px 0, rgba(93, 100, 148, .2) 0 4px 6px 0;
}

.button-24:disabled {
    cursor: not-allowed;
    opacity: .5;
}
.center-text{
    text-align: center;
}

@media only screen and (max-width: 864px) {
    .contact{
        &__body{
            margin-top: 3rem;
            &__heading{
                font-size: 16px;
                padding-bottom: 20px;
            }
            &__desc__text{
                font-size: 12px;
                width:180px;
                height:200px;
            }
        }
    }
    .button-24{
        margin: 25px 0;
        padding: 6px 12px;
        font-size: 8px;
    }
}