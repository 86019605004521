.navbar-container{
    display: flex;
    position: fixed;
    justify-content: space-between;
    padding: 1rem 1.5rem 0rem 1.5rem;
    font-family: "Noto Sans",sans-serif;
    font-weight: 700;
    height: fit-content;
    width: calc(100vw - 3rem);
    z-index:1000;
    background-color: rgb(249, 247, 248,0.7);
    backdrop-filter: blur(10px);
    &__brand-label{
        
    }
    &__menubar{
        display: flex;
        justify-content: center;
        &__menu-item{
            text-decoration: none;
            color: #1f2d85;
            padding: 0.5rem;
            margin: 0.7rem 0.4rem;
            height: fit-content;
            &.border-bottom{
                border-bottom: 2px solid #1f2d85;
            }
            &:hover {
                    border-bottom: 2px solid #1f2d85;
                }
            &.contact-us {
                    background-color: #f37922;
                    color: #fff;
                    border-radius: 24px;
                        border: 1.5px solid #fff;
                    &:hover {
                        background-color: #fff;
                        color: #f37922;
                        border: 1.5px solid #1f2d85;
                    }
            }
            
    }
        
    }
}
.sidebar-menu-icon{
    display: none;
}
/* CSS */
button.button-2 {
    touch-action: manipulation;
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    color: inherit;
    font: inherit;
    background: transparent;
    padding: 0;
    margin: 0;
    border-radius: 0;
    text-align: inherit;
    text-transform: inherit;
    -webkit-tap-highlight-color: transparent;
}

button.button-2:focus {
    outline-style: solid;
    outline-color: transparent;
}

button.button-2 {
    touch-action: manipulation;
    margin-top: 0.7rem;
    cursor: pointer;
}

button.button-2 {
    touch-action: manipulation;
    border: 1px solid #0360df;
    border-radius: 50px;
    padding: 10px 12px;
    background-color: #0360df;
    background-image: radial-gradient(75% 50% at 50% 0%, #f4feff 12%, transparent), radial-gradient(75% 50% at 50% 85%, #8de3fc, transparent);
    box-shadow: inset 0 0 2px 1px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(17, 110, 231, 0.2), 0 1px 4px 1px rgba(0, 0, 0, 0.1);
    color: #fff;
    text-shadow: 0 1px 1px #116ee7;
    transition-property: border-color, transform, background-color;
    transition-duration: 0.2s;
}

button.button-2:hover {
    transform: scale(1.04);
}

button.button-2:active {
    border-color: #0048d5;
    transform: scale(0.96);
    background-color: #0048d5;
}
@media only screen and (min-width: 864px) {

        .sidebar-top{
            display: none;
        }
}
@media only screen and (max-width: 864px) {
    .sidebar-menu-icon {
        display: block;
        margin-top: 11.2px;
        cursor: pointer;
    }
        .menu-toggle {
            .sidebar-top{
                display: block;
                position: absolute;
                    left: 20px;
                    top: 20px;
            }
            .close-sidebar {
                box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
                padding: 4px 8px;
                border-radius: 6px;
                width: fit-content;
                cursor: pointer;
                margin-bottom: 40px;
            }
        }
    .navbar-container__menubar__menu-item {
        width: fit-content;
        border: 2px solid white;
        font-size: 12px;
    }
    .menu-toggle{
        display: flex;
        flex-direction: column;
        position: absolute;
        height: calc(100vh - 3px);
        border-radius: 12px;
        background-color: #fff;
        margin: -14px 0 0px -24px;
        width: 380px;
        padding-left: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }
    .sm-disp-none{
        display: none;
    }
    .contact-button{
        font-size: 12px;
    }
}
